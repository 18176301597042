import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="col-12 wrapper main-bggrey p-0">
      <div class="col-12 inner-page-content home-inner  px-0">
        <section className="workflow-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12  d-flex flex-column sectiontop-pd ">
                   <div style={{minHeight:"100vh"}} className="quote-banner workflow text-center case-heading my-auto ml-0 ">
                        <h3>PAGE NOT FOUND</h3>
                         <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                   </div>
              </div>
              
            </div>
            
          </div>
          
           
        </section>
       </div>
    </div>  
  </Layout>
)

export default NotFoundPage
